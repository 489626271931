@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
}

@layer components {

  .home {
    @apply bg-none;
    
  };

  .error {
    @apply bg-arkanic-teal text-arkanic-red
    ;
  };

  .errorLink {
    @apply text-arkanic-blue hover:text-arkanic-lightblue
    
    /* hover:text-emerald-600 hover:drop-shadow-[0_0_5px_rgba(128,128,255,0.8)]; */

  }


  .mainNavBar {
    @apply bg-arkanic-blue
    ;
  };

  .mainNavLink {
    @apply text-arkanic-popblue hover:activeMainNavLink
    ;
  };

  .activeMainNavLink {
    @apply text-arkanic-popgreen font-bold
    ;
  };

  .mainNavContent {
    @apply bg-none;
    /* @apply bg-arkanic-lightgreen; */
  };

  .pageNavLink {
    @apply
    text-arkanic-blue hover:text-arkanic-green hover:font-semibold
    p-8 mob:p-4
    border-arkanic-blue hover:border-arkanic-green border-4 rounded-3xl
    ;
  };

  .subNavBar {
    @apply mainNavBar
    ;
  };

  .subNavLink {
    @apply mainNavLink
    ;
  };

  .activeSubNavLink {
    @apply activeMainNavLink
    ;
  };

  .subNavContent {
    @apply bg-none;

  };

  .textBasic {
    @apply
    text-arkanic-blue
    ;
  };

  .mainTextHead {
    @apply
    textBasic
    flex flex-col justify-evenly items-center text-center
    text-7xl font-semibold my-16 px-[8%]
    mob:text-5xl
    ;
  };

  .mainTextBody {
    @apply
    textBasic
    text-center
    text-3xl my-16 px-[8%]
    ;
  };

  .textVideoTitle {
    @apply
    mainTextBody
    mb-4
    mob:text-xl
  }

  .baseBorder {
    @apply
    border-4 border-arkanic-blue rounded-3xl
    ;
  }

  .pageBorder {
    @apply baseBorder m-[4%]
  }
  
  .pageBorderDiv {
    @apply baseBorder border-2 m-[4%]
  }

  .trackingBorder {
    @apply baseBorder
    ;
  }

  .homeText {
    @apply
    text-black
    text-center
    font-semibold
    text-4xl mob:text-2xl
    my-4
    p-2 py-4
  }

  .clickable {
    @apply
    border-black border-4 rounded-3xl
  }

  .homeIcon {
    @apply
    aspect-square max-h-full max-w-full p-16 pt-0
    mob:p-[15%] mob:pt-0
  }
  

}



@layer utilities {
  
  .scrollbar {
    scrollbar-width: none;
    /* scrollbar-color: #000 #fff; */
  }

}



/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  #find fonts
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    #add Hack
} */
